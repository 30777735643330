import React, { useContext, useEffect, useState } from "react";
import "./ProductDetail.scss";
import BreadCrumb from "../../components/BreadCrumb";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { ReactComponent as Manat } from "../../assets/manatBlack.svg";
import { ReactComponent as Bag } from "../../assets/Bag.svg";
import { ReactComponent as Heart } from "../../assets/heart.svg";
import { ReactComponent as Share } from "../../assets/share.svg";
import { ReactComponent as Facebook } from "../../assets/newsFacebook.svg";
import { ReactComponent as Twitter } from "../../assets/twitter.svg";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Rating } from "react-simple-star-rating";
import "react-tabs/style/react-tabs.css";
import ApiService from "../../api/axios";
import { Link } from "react-router-dom";
import Meta from "../../components/Meta/Meta";
import { addTosWishlist } from "../../helpers/functions";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { Pagination } from "swiper";
import ProductCard from "../../components/ProductCard";
import { cartItemsContext, quantityContext } from "../../components/Store";
import { _t } from "../../helpers/helpers";
import AddToCartPopup from "../../components/ProductCard/PopupAddToBasket";
import { Comments, FacebookProvider, Share as FbShare } from "react-facebook";
import ShareLink from "react-twitter-share-link";
import { QuantityPicker } from "react-qty-picker";
import Swal from "sweetalert2";
import { Thumbs } from "swiper";
import { ReactComponent as Whatsapp } from "../../assets/wp.svg";
// import { ShareSocial } from "react-share-social";
import YouTube from "react-youtube";

import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { tokenContext } from "../../components/Store";

const ProductDetail = (props) => {
  const [productDetail, setProductDetail] = useState({
    title_image: {
      slider: "",
    },
    volumes: [
      {
        title_image: "",
      },
    ],
    author: {},
    brand: {},
    groups: [],
    topNotes: [],
    heartNotes: [],
    baseNotes: [],
    similars: [],
  });
  const [isActive, setIsActive] = useState(false);
  const [cartItems, setCartItems] = useContext(cartItemsContext);
  const [quantity, setQuantity] = useContext(quantityContext);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [token, setToken] = useContext(tokenContext);

  const [count, setCount] = useState(1);
  const [volume_id, setVolume] = useState(0);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pageMeta, setPageMeta] = useState([]);
  // const [outOfStock, setOutOfStock] = useState();
  useEffect(() => {
    ApiService.get(
      `products/getProductInfoByUrl/${props.match.params.alias}`
    ).then((resp) => {
      setProductDetail(resp.data.data);
      console.log(productDetail.similars);
      if (resp.data.data.out_of_stock == 1) {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: _t("out of stock"),
          showConfirmButton: true,
          timer: 5000,
        });
      }
      setVolume(resp.data.data.volumes[0].id);
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
    });
  }, [props.match.params.alias]);

  const addToCart = (e, product_id) => {
    let item = { product_id, volume_id, count };
    let cart = cartItems;
    // cart[product_id] = item; // this is the line that is causing the issue
    if (cart.findIndex((item) => item.product_id === product_id) !== -1) {
      cart = cart.map((item) => {
        if (item.product_id === product_id) {
          item.count += count;
          item.volume_id = volume_id;
        }
        return item;
      });
    } else {
      cart.push(item);
    }
    localStorage.setItem("cartItems", JSON.stringify(cart));
    setCartItems(cart);
    setQuantity(quantity + item.count);
    localStorage.setItem("quantity", quantity + item.count);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: _t("message.basket_added"),
      showConfirmButton: false,
      timer: 1000,
    });
    // setCloseActive(false);
    // let item = { product_id, volume_id, count };
    // let cart = cartItems;
    // cart.push(item);
    // console.log(item);
    // localStorage.setItem("cartItems", JSON.stringify(cart));
    // setCartItems(cart);
    // setQuantity(quantity + item.count);
    // localStorage.setItem("quantity", quantity + item.count);
    // Swal.fire({
    //   position: "top-end",
    //   icon: "success",
    //   title: _t("message.basket_added"),
    //   showConfirmButton: false,
    //   timer: 2000,
    // });
  };
  const opts = {
    height: "413",
    width: "100%",
  };
  return (
    <div className="productDetail">
      {isActive ? <AddToCartPopup product={props.product} /> : null}
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className="row">
          <div className="xl-1 lg-1 md-1 sm-12"></div>
          <div className="xl-11 lg-11 md-12 sm-12">
            <div className="productDetailHeader">
              <div className="title">{productDetail.title}</div>
              <div className="productCardRating">
                <Rating
                  fillColor={"#FF0852"}
                  readonly={true}
                  allowHalfIcon={true}
                  iconsCount={5}
                  size={20}
                  ratingValue={productDetail.rating * 20} /* Available Props */
                />
                <span>({productDetail.ratingCount})</span>
                <Link to={`/products?brand=${productDetail.brand.alias}`}>
                  <p>{productDetail.brand.title}</p>
                </Link>
              </div>
            </div>
          </div>
          <div className="xl-1 lg-0 md-0 sm-0"></div>
          <div className="xl-5 lg-6 md-6 sm-12 productDetailSlider ">
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              modules={[Thumbs]}
              thumbs={{ swiper: thumbsSwiper }}
            >
              {productDetail.media &&
                productDetail.media.map((image) => {
                  return (
                    <SwiperSlide key={image.id}>
                      <LazyLoadImage
                        src={image.main}
                        alt="product img"
                        effect="blur"
                      />
                    </SwiperSlide>
                  );
                })}
              <SwiperSlide key={0}>
                <LazyLoadImage
                  src={productDetail.title_image.main}
                  alt="product img"
                  effect="blur"
                />
              </SwiperSlide>
              {productDetail.volumes.map((volume, index) => {
                return (
                  <SwiperSlide key={`volumes_${volume.id}`}>
                    {volume.title_image.id == 0 ? (
                      <>
                        {productDetail.brand.is_special ? (
                          <LazyLoadImage
                            src={productDetail.thumbnail}
                            alt="product img"
                            className="imgPath"
                            effect="blur"
                          />
                        ) : (
                          <LazyLoadImage
                            src={`https://api.iyde.az/frontend/images/volumes/${volume.volume}-site.png`}
                            alt="product img"
                            className="imgPath"
                            effect="blur"
                          />
                        )}
                      </>
                    ) : (
                      <LazyLoadImage
                        src={
                          volume.title_image.thumbnail
                            ? volume.title_image.thumbnail
                            : null
                        }
                        alt="product img"
                        className="imgPath"
                        effect="blur"
                      />
                    )}
                  </SwiperSlide>
                );
              })}
              {productDetail.brand.is_special == 0 ? (
                <div className="hoverEffectSpan">
                  Şəkil tanıtım xarakteri daşıyır. Ətir İydə qablaşdırılmasında
                  çatdırılır.
                </div>
              ) : null}
            </Swiper>
            {token ? (
              <div className="heart desk-none">
                <label
                  className="heart"
                  onClick={(e) => addTosWishlist(e, productDetail.id)}
                >
                  <input
                    checked={
                      localStorage.wishlist &&
                      localStorage.wishlist.includes(productDetail.id)
                        ? "checked"
                        : null
                    }
                    className="like-button"
                    type="checkbox"
                  />
                  <Heart />
                </label>
              </div>
            ) : null}
          </div>
          <div className="xl-6 lg-6 md-6 sm-12">
            <div className="productDetailRight">
              <h5>{_t("site_content.select_perfume_volume")}:</h5>
              <div className="productDetailSize">
                <Swiper
                  modules={[Thumbs]}
                  watchSlidesProgress
                  onSwiper={setThumbsSwiper}
                  spaceBetween={10}
                  noSwiping={true}
                  slidesPerView={2.6}
                >
                  <SwiperSlide key={0}>
                    <div className="swiperThumbsImg">
                      <LazyLoadImage
                        src={productDetail.title_image.main}
                        alt="product imgf"
                        effect="blur"
                      />
                    </div>
                  </SwiperSlide>
                  {productDetail.volumes.map((volume, index) => {
                    return (
                      <SwiperSlide key={`volumes_${volume.id}`}>
                        {productDetail.brand.is_special ? (
                          <label>
                            <input
                              defaultChecked={index == 0}
                              type="radio"
                              name="size"
                            />
                            <div className="sizeCard">
                              {volume.title_image.id == 0 ? (
                                <>
                                  <LazyLoadImage
                                    src={productDetail.title_image.thumbnail}
                                    alt="product img"
                                    className="imgPath"
                                    effect="blur"
                                  />
                                </>
                              ) : (
                                <LazyLoadImage
                                  src={
                                    volume.title_image.thumbnail
                                      ? volume.title_image.thumbnail
                                      : null
                                  }
                                  alt="product img"
                                  className="imgPath"
                                  effect="blur"
                                />
                              )}
                              <span>{volume.volume}</span>
                              <div className="price">
                                {volume.sale_price == 0 ||
                                volume.sale_price == null ? (
                                  volume.price
                                ) : (
                                  <>
                                    {volume.price != volume.sale_price
                                      ? volume.sale_price
                                      : volume.price}
                                    <Manat />
                                    {volume.price != volume.sale_price ? (
                                      <span>
                                        {volume.price}
                                        <Manat />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </div>
                            </div>
                          </label>
                        ) : (
                          <label>
                            <input
                              onChange={(e) => setVolume(volume.id)}
                              defaultChecked={index == 0}
                              type="radio"
                              name="size"
                            />
                            <div className="sizeCard">
                              {volume.title_image.id == 0 ? (
                                <LazyLoadImage
                                  src={`https://api.iyde.az/frontend/images/volumes/thumbs/${volume.volume}-site.png`}
                                  alt="product img"
                                  className="imgPath"
                                  effect="blur"
                                />
                              ) : (
                                <LazyLoadImage
                                  src={
                                    volume.title_image.thumbnail
                                      ? volume.title_image.thumbnail
                                      : null
                                  }
                                  alt="product img"
                                  className="imgPath"
                                  effect="blur"
                                />
                              )}
                              <span>{volume.volume}</span>
                              <div className="price">
                                {volume.sale_price == 0 ||
                                volume.sale_price == null ? (
                                  volume.price
                                ) : (
                                  <>
                                    {volume.price != volume.sale_price
                                      ? volume.sale_price
                                      : volume.price}
                                    <Manat />
                                    {volume.price != volume.sale_price ? (
                                      <span>
                                        {volume.price}
                                        <Manat />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </div>
                            </div>
                          </label>
                        )}
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>

              <div className="productDetailButtons">
                <QuantityPicker
                  value={count}
                  max={9999}
                  min={1}
                  onChange={(newValue) => {
                    setCount(newValue);
                  }}
                />
                {productDetail.out_of_stock == 0 ? (
                  <button
                    type="button"
                    className="addToCard"
                    onClick={(e) => addToCart(e, productDetail.id)}
                  >
                    <Bag />
                    {_t("buttons.add_to_basket")}
                  </button>
                ) : (
                  <button type="button" className="addToCard" disabled>
                    <Bag />
                    {_t("buttons.add_to_basket")}
                  </button>
                )}
                {token ? (
                  <div className="productDetailHeart resp-none">
                    <label
                      className="heart"
                      onClick={(e) => addTosWishlist(e, productDetail.id)}
                    >
                      <input
                        checked={
                          localStorage.wishlist &&
                          localStorage.wishlist.includes(productDetail.id)
                            ? "checked"
                            : null
                        }
                        className="like-button"
                        type="checkbox"
                      />
                      <Heart />
                    </label>
                  </div>
                ) : null}
              </div>
              <div className="productDetailSocials">
                <div className="newsContentBottom">
                  <CopyToClipboard
                    onCopy={() => {
                      Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: _t("message.copied"),
                        showConfirmButton: false,
                        timer: 1000,
                      });
                    }}
                    text={window.location.href}
                  >
                    <span>
                      <Share />
                    </span>
                  </CopyToClipboard>
                  {/* <FacebookProvider appId="812101143682066">
                    <FbShare href={window.location.href}>
                      {({ handleClick, loading }) => (
                        <span>
                          <Facebook
                            type="button"
                            disabled={loading}
                            onClick={handleClick}
                          />
                        </span>
                      )}
                    </FbShare>
                  </FacebookProvider> */}
                  <ShareLink link={window.location.href}>
                    {(link) => (
                      <a href={link} target="_blank">
                        <Twitter />
                      </a>
                    )}
                  </ShareLink>
                  {/* <ShareSocial
                    url={window.location.href}
                    socialTypes={[
                      "facebook",
                      "twitter",
                      "telegram",
                      "linkedin",
                    ]}
                  /> */}
                </div>
                {productDetail.out_of_stock == 0 ? (
                  <a
                    href={`https://wa.me/994515112050/?text= Salam bu etri sifaris etmek isteyirem https://iyde.az/products/${props.match.params.alias}`}
                    target="_blank"
                    className="oneClickOrder"
                  >
                    <Whatsapp />
                    Bir kliklə sifariş et
                  </a>
                ) : (
                  <a
                    href={`https://wa.me/994515112050/?text= Salam, bu etir yeniden stock-a gelende mene xeber edin zehmet olmasa https://iyde.az/products/${props.match.params.alias}`}
                    target="_blank"
                    className="oneClickOrder"
                  >
                    <Whatsapp />
                    Bir kliklə sifariş et
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="xl-1 lg-1 md-1 sm-12"></div>
          <div className="xl-1 lg-1 md-1 sm-12"></div>
          <div className="xl-10 lg-10 md-10 sm-12">
            <Tabs>
              <div className="productDetailTab">
                <TabList>
                  <Tab>{_t("site_content.product.parameters")}</Tab>
                  <Tab>{_t("site_content.product.information")}</Tab>
                  {productDetail.video_link == null ? null : (
                    <Tab>{_t("title.video")}</Tab>
                  )}

                  {/* <Tab>{_t("site_content.product.comments")}</Tab> */}
                </TabList>
              </div>

              <TabPanel>
                <div className="productDetailIndicators">
                  <div className="productDetailIndicatorsChild">
                    <p>
                      <strong> {_t("forms.date")}:</strong>
                      <span>{productDetail.date}</span>
                    </p>
                    <p>
                      <strong>{_t("forms.brand")}:</strong>
                      <Link to={`/products?brand=${productDetail.brand.alias}`}>
                        {productDetail.brand.title}
                      </Link>
                    </p>
                    <p>
                      <strong> {_t("forms.gender")}:</strong>
                      <Link
                        to={`/products?gender=${productDetail.gender_alias}`}
                      >
                        {productDetail.gender}
                      </Link>
                    </p>
                    <p>
                      <strong> {_t("title.parfumer")}:</strong>
                      <Link
                        to={`/products?author=${productDetail.author.alias}`}
                      >
                        {productDetail.author.full_name}
                      </Link>
                    </p>
                    <div>
                      {/* <p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Quos consequatur nihil sunt necessitatibus,
                        possimus, vel officia mollitia ratione incidunt, culpa
                        harum ad totam accusamus eum. Minima optio similique
                        dignissimos odio?
                      </p>
                      <a href=""><datagrid>sdd</datagrid></a>
                      <div className="paragraf">
                        njjn
                      </div> */}
                    </div>
                    <p>
                      <strong> {_t("forms.season")}:</strong>
                      <span>{_t(productDetail.season)}</span>
                    </p>
                  </div>
                  <div className="productDetailIndicatorsChild">
                    {productDetail.groups == 0 ? null : (
                      <p>
                        <strong>{_t("forms.groups")}:</strong>
                        {productDetail.groups.map((group) => {
                          return (
                            <Link to={`/products?group=${group.alias}`}>
                              {group.title}
                            </Link>
                          );
                        })}
                      </p>
                    )}

                    {productDetail.topNotes == 0 ? null : (
                      <p>
                        <strong>{_t("forms.top_notes")}:</strong>
                        {productDetail.topNotes.map((topNote) => {
                          return (
                            <Link to={`/products?note=${topNote.alias}`}>
                              {topNote.title}
                            </Link>
                          );
                        })}
                      </p>
                    )}

                    {productDetail.heartNotes == 0 ? null : (
                      <p>
                        <strong>{_t("forms.heart_notes")}:</strong>

                        {productDetail.heartNotes.map((heartNote) => {
                          return (
                            <Link to={`/products?note=${heartNote.alias}`}>
                              {heartNote.title}
                            </Link>
                          );
                        })}
                      </p>
                    )}

                    {productDetail.baseNotes == 0 ? null : (
                      <p>
                        <strong> {_t("forms.base_notes")}:</strong>

                        {productDetail.baseNotes.map((baseNote) => {
                          return (
                            <Link to={`/products?note=${baseNote.alias}`}>
                              {baseNote.title}
                            </Link>
                          );
                        })}
                      </p>
                    )}

                    {productDetail.part_of_day == 0 ? null : (
                      <p>
                        <strong> {_t("forms.part_of_day")}:</strong>
                        <span>{_t(productDetail.part_of_day)}</span>
                      </p>
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div
                  className="productDetailInfo"
                  dangerouslySetInnerHTML={{
                    __html: productDetail.content,
                  }}
                ></div>
              </TabPanel>
              {/* <TabPanel>
                <FacebookProvider appId="348228100426228">
                  <Comments autoLogAppEvents={1} href={window.location.href} />
                </FacebookProvider>
              </TabPanel> */}
              {productDetail.video_link == null ? null : (
                <TabPanel>
                  <YouTube videoId={productDetail.video_link} opts={opts} />
                </TabPanel>
              )}
            </Tabs>
          </div>
          <div className="xl-1 lg-1 md-1 sm-12"></div>

          {productDetail.similars && productDetail.similars.length > 0 ? (
            <div className="xl-12 lg-12 md-12 sm-12 similarProducts ">
              <div className="subtitle">Oxşar məhsullar</div>
              <div className="productSlides">
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={16}
                  slidesPerView={4}
                  loop={false}
                  navigation
                  pagination={{ clickable: true }}
                  className="paginationSwiper"
                  breakpoints={{
                    1199: {
                      slidesPerView: 4,
                    },
                    767: {
                      slidesPerView: 3,
                    },
                    500: {
                      slidesPerView: 1.8,
                    },
                    320: {
                      slidesPerView: 1.2,
                    },
                  }}
                >
                  {productDetail.similars.map((similar) => {
                    return (
                      <SwiperSlide key={`similars_${similar.id}`}>
                        <ProductCard product={similar} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          ) : null}
          {/* {productDetail.similars.map((similar) => {
            return (
              <div key={similar.id}>
                <ProductCard product={similar} />
              </div>
            );
          })} */}
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
