import React, { useEffect, useState } from "react";
import "./NewsDetail.scss";
import BreadCrumb from "../../components/BreadCrumb";
import { ReactComponent as Eye } from "../../assets/eye.svg";
import { ReactComponent as Share } from "../../assets/share.svg";
import { ReactComponent as Facebook } from "../../assets/newsFacebook.svg";
import { ReactComponent as Twitter } from "../../assets/twitter.svg";
import NewsCard from "../../components/NewsCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Swal from "sweetalert2";
import { _t } from "../../helpers/helpers";
import "swiper/css/pagination";
import SwiperCore, { Pagination } from "swiper";
import ApiService from "../../api/axios";
import Meta from "../../components/Meta/Meta";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { Comments, FacebookProvider, Share as FbShare } from "react-facebook";
import ShareLink from "react-twitter-share-link";

SwiperCore.use([Pagination]);

const NewsDetail = (props) => {
  const [newsDetails, setNewsDetails] = useState({
    title_image: {
      main: "",
    },
  });
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pageMeta, setPageMeta] = useState({});
  const [excerpts, setExcerpts] = useState([]);
  const [alias, setAlias] = useState(props.match.params.alias);

  useEffect(() => {
    let alias = props.match.params.alias;
    ApiService.get(`news/detailsByUrl/${alias}`).then((resp) => {
      setNewsDetails(resp.data.data);
      setExcerpts(resp.data.excerpts);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
      setPageMeta(resp.data.page_meta);
    });
  }, [props.match.params.alias]);
  return (
    <div className="newsDetail">
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className="row">
          <div className="xl-12 lg-12 md-12 sm-12">
            <div className="newsDetailHeader">
              <h5>{newsDetails.title}</h5>
              <p>
                {newsDetails.dateHumanShort}
                {/* <span>
                  <Eye />
                  201
                </span> */}
              </p>
              <img src={newsDetails.title_image.main} alt="news img" />
            </div>
          </div>
          <div className="xl-2 lg-2 md-2 sm-12"></div>
          <div className="xl-8 lg-8 md-8 sm-12">
            <div className="newsdetailContent">
              <span
                dangerouslySetInnerHTML={{
                  __html: newsDetails.description,
                }}
              ></span>
            </div>
          </div>
          <div className="xl-2 lg-2 md-2 sm-12"></div>
          <div className="xl-2 lg-2 md-2 sm-12"></div>
          <div className="xl-8 lg-8 md-8 sm-12">
            <div className="newsdetailContent">
              <div
                dangerouslySetInnerHTML={{
                  __html: newsDetails.content,
                }}
              ></div>
            </div>
          </div>

          <div className="xl-12 lg-12 md-12 sm-12">
            <div className="newsContentBottom">
              <span>{_t("frontend.share_items")}</span>

              <CopyToClipboard
                onCopy={() => {
                  Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: _t("message.copied"),
                    showConfirmButton: false,
                    timer: 1000,
                  });
                }}
                text={window.location.href}
              >
                <span className="copy">
                  <Share />
                </span>
              </CopyToClipboard>
              {/* <FacebookProvider appId="812101143682066">
                <FbShare href={window.location.href}>
                  {({ handleClick, loading }) => (
                    <span className="copy">
                      <Facebook
                        type="button"
                        disabled={loading}
                        onClick={handleClick}
                      />
                    </span>
                  )}
                </FbShare>
              </FacebookProvider> */}

              <ShareLink link={window.location.href}>
                {(link) => (
                  <a href={link} target="_blank">
                    <Twitter />
                  </a>
                )}
              </ShareLink>
            </div>
          </div>
          <div className="xl-12 lg-12 md-12 sm-12 newsContentBottomCard">
            <div className="title">Dİgər xəbərlər</div>
            <Swiper
              modules={[Pagination]}
              spaceBetween={16}
              slidesPerView={3}
              loop={false}
              navigation
              pagination={{ clickable: true }}
              className="paginationSwiper "
              breakpoints={{
                1199: {
                  slidesPerView: 3,
                },
                767: {
                  slidesPerView: 2.4,
                },
                500: {
                  slidesPerView: 1.8,
                },
                320: {
                  slidesPerView: 1,
                },
              }}
            >
              {excerpts.map((excerpt) => (
                <SwiperSlide key={excerpt.id}>
                  <NewsCard news={excerpt} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
