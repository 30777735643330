import React, {useContext, useEffect, useState} from 'react';
import './About.scss';
import ApiService from '../../../api/axios';
import {localeContext} from "../../Store";
const About = (props) => {
    const [about, setAbout] = useState({});
    const [locale,setLocale] =useContext(localeContext);
    useEffect(() => {
        ApiService.get("index/about").then(
            (resp) => {
                setAbout(resp.data.data)
            }
        )

    },[locale])
    return (
        <div className="aboutSection">
            <div className="container">
                <div className="aboutSectionChild">
                    <div className="title">
                        {about.title}
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: about.content,
                        }}>
                    </div>
                    {/* <div className="subTitle">
                            {this.state.about.page_blocks.title}
                        </div>
                        <p>İydə parfüm evi Azərbaycan bazarında illərdir ki, ətirlərin satışı ilə məşğuldur. Sizə və sevdiyiniz insanlar üçün original və yüksək keyfiyyətli etir təqdim edirik. Bütün dünyada tanınmış
                            <span>Giorgio Armani</span>,<span> Christian Dior</span>,<span> Montale</span>, <span>Amouge</span>, <span>Dolce&Gabbana</span>  kimi məşhur brendlərin və ən əsası qalıcı ətirlərinin Bakıda İydə perfumery-dən əldə edə bilərsiniz. </p>
                 */}
                </div>
            </div>
        </div>
    )
}

export default About
