import React, { useEffect, useState } from "react";
import "../Authentication.scss";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { ReactComponent as User } from "../../../assets/user.svg";
import { Link } from "react-router-dom";
import ApiService from "../../../api/axios";
import InputMask from "react-input-mask";
import SimpleReactValidator from "simple-react-validator";
// import SocialLogin from "../SocialLogin";
import { _t } from "../../../helpers/helpers";

const Register = (props) => {
  // constructor() {
  //   super();
  //   this.state = {
  //     full_name: "",
  //     password: "",
  //     // email: '',
  //     phone: "",
  //     // password_confirmation: '',
  //     provider_id: "",
  //     provider: "",
  //     avatar: "",
  //   };
  //   this.validator = new SimpleReactValidator();
  //   this.handlefullNameChange = this.handlefullNameChange.bind(this);
  //   // this.handleEmailChange = this.handleEmailChange.bind(this);
  //   this.handlePhoneChange = this.handlePhoneChange.bind(this);
  //   this.handlePasswordChange = this.handlePasswordChange.bind(this);
  //   // this.handlePasswordConfirmationChange = this.handlePasswordConfirmationChange.bind(this);
  //   this.handleSubmit = this.handleSubmit.bind(this);
  // }
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  let validator = new SimpleReactValidator();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validator.allValid()) {
      ApiService.post("auth/register", {
        full_name: fullName,
        password,
        phone,
      }).then((response) => {
        if (response.data.success) {
          props.history.push({
            pathname: "code-register",
            search: `?phone=${phone}`,
          });
        }
      });
    } else {
      validator.showMessages();
    }
  };
  return (
    <div className="authentication register">
      <div className="auth-content">
        <div className="left">
          <Link to="/">
            <Logo />
          </Link>
          <p>{_t("content_login")}</p>
        </div>
        <div className="right">
          <div className="auth-content-child">
            <div className="authContentChildHeader">
              <Link to="/login" className="Login ">
                {_t("pages.login.title")}
              </Link>
              <Link to="/register" className="Register active">
                {_t("title.register")}
              </Link>
            </div>
            <form className="authContentBody" onSubmit={handleSubmit}>
              <div className="input-group input-group-validate">
                <input
                  type="text"
                  placeholder={_t("forms.name")}
                  name="full_name"
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>

              <div className="input-group input-group-validate">
                <InputMask
                  mask="+\9\9\4999999999"
                  maskChar=" "
                  name="phone"
                  placeholder={_t("forms.numbers")}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

              <div className="input-group input-group-validate">
                <input
                  type="password"
                  placeholder={_t("forms.password")}
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="input-group">
                <button className="blackButton">
                  <User />
                  {_t("title.register")}
                </button>
              </div>
              <div className="authContentFooter">
                <div className="authContentFooterChild">
                  <button>{_t("buttons_resend_code")}</button>
                </div>
              </div>
            </form>
            {/* <SocialLogin /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
