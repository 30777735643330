import React, { useContext, useEffect, useState } from "react";
import "./OrderHistory.scss";
import AccountSidebar from "../../../components/AccountSidebar";
import BreadCrumb from "../../../components/BreadCrumb";
import { Link } from "react-router-dom";
import { ReactComponent as Manat } from "../../../assets/manatBlack.svg";
import { ReactComponent as Logo } from "../../../assets/logo.svg";

import OrderHistoryCard from "../../../components/OrderHistoryCard";
import LogOut from "../../../components/Logout/LogOut";
import ApiService from "../../../api/axios";
import { _t } from "../../../helpers/helpers";
import { tokenContext } from "../../../components/Store";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { ReactComponent as Arrow } from "../../../assets/arrow.svg";
import { render } from "@testing-library/react";
import OrderImg from "../../../assets/product.png";
import { ReactComponent as ManatBlack } from "../../../assets/manat.svg";
import { Rating } from "react-simple-star-rating";
import Meta from "../../../components/Meta/Meta";
import Swal from "sweetalert2";

const OrderHistory = () => {
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [alreadyRated, setAlreadyRated] = useState(false);
  const [orderHistories, setOrderHistories] = useState([]);
  const [token, setToken] = useContext(tokenContext);
  // const [date, setDate] = useState();
  //   const [totalPrice, setTotalPrice] = useState(0);

  // Catch Rating value
  const handleRating = (rate, product_id) => {
    ApiService.post(
      "/account/rate",
      { star: rate / 20, product_id: product_id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((res) => {
      setAlreadyRated(false);
      Swal.fire(res.data.title, res.data.description, "success");
    });
  };

  useEffect(() => {
    ApiService.get("account/orders", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((resp) => {
      setOrderHistories(resp.data.data);
      setPageMeta(resp.data.meta.meta_title);
      setBreadcrumbs(resp.data.meta.breadcrumbs);
      //   setTotalPrice(resp.data.total);
      // setDate(resp.data.data.date);
    });
  }, []);
  return (
    <div className="profile orderHistoryPage">
      <Meta meta={pageMeta} />

      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />

        <div className="account-header">
          <div className="title">
            <h5>{_t("pages.personal_info.title")}</h5>
            <p>{_t("account_content")}</p>
            <LogOut />
          </div>
        </div>
        <div className="row">
          <div className="xl-3 lg-3 md-3 sm-12 positionRelative">
            <AccountSidebar />
          </div>
          <div className="xl-9 lg-9 md-9 sm-12">
            {orderHistories ? (
              <div className="account-order">
                <div className="accountCard accountCardParent">
                  <span>{_t("order.number")}</span>
                  <span>{_t("table.header.date")}</span>
                  <span>{_t("table.header.total")}</span>
                  <span>{_t("table.header.order.status")}</span>
                  <span>{_t("table.header.payment_status")}</span>
                </div>
                {orderHistories.map((orderHistory) => {
                  return (
                    <Accordion>
                      <AccordionSummary
                        // expandIcon={<Arrow />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="orderHistoryCardParent"
                      >
                        <div className="account-order-header accountCard">
                          <span>{orderHistory.id}</span>
                          <span>{orderHistory.date}</span>
                          <span>{orderHistory.total_price}</span>
                          <span>{orderHistory.status}</span>
                          <span>{orderHistory.payment_type}</span>
                        </div>
                        <Arrow />
                      </AccordionSummary>
                      {orderHistory.order_items.map((value) => {
                        return (
                          <AccordionDetails>
                            <div className="accountOrderCard">
                              <div className="orderHistoryCard">
                                <div className="imgContainer">
                                  <img
                                    src={value.product.title_image.main}
                                    alt="order img"
                                  />
                                </div>
                                <div className="orderHistoryDetail">
                                  <h6>{value.product.title}</h6>
                                  <div className="rating-line">
                                    <div className="rating-card">
                                      {/* <Rating
                                        fillColor={"#FF0852"}
                                        readonly={value.isRated}
                                        allowHalfIcon={true}
                                        onClick={(rate) =>
                                          handleRating(rate, value.pr_id)
                                        }
                                        iconsCount={5}
                                        size={20}
                                        ratingValue={value.rating}
                                      /> */}
                                    </div>
                                    <div className="left desk-none">
                                      {value.volume.volume}
                                    </div>
                                  </div>
                                  <div className="orderHistoryDetailButton">
                                    <div className="left resp-none">
                                      {value.volume.volume}
                                      <span>{value.count} ədəd</span>
                                    </div>
                                    <div className="right">
                                      <p>
                                        {value.volume.price}
                                        <Manat />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        );
                      })}
                      <div className="orderDelivery">
                        <div className="orderDeliveryChild">
                          {_t("label.total_order_amount")}:
                          <span>{orderHistory.total_price}</span>
                        </div>
                        <div className="orderDeliveryChild">
                          {_t("title.delivery")}:
                          <span>{orderHistory.delivery}</span>
                        </div>

                        <div className="orderDeliveryChild totalPriceOrder">
                          {_t("table.header.total_price")}:
                          <span>{orderHistory.total_payment}</span>
                        </div>
                      </div>
                    </Accordion>
                  );
                })}
              </div>
            ) : (
              <div className="row">
                <div className="xl-12 lg-12 md-12 sm-12">
                  <div className="cartEmpty">
                    <div className="cartEptyContent">
                      <Link to="/">
                        <Logo />
                      </Link>
                      <p>{_t("favorites.empty")}</p>
                      <Link
                        to="/products"
                        className="blackButton cartEmptyButton"
                      >
                        {_t("buttons.back_to_shop")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
