import React, { useContext, useState } from "react";
import "./ProductCard.scss";
import { ReactComponent as Heart } from "../../assets/heartMini.svg";
import { ReactComponent as ManatBlack } from "../../assets/manatBlack.svg";
import { ReactComponent as ManatPink } from "../../assets/manatPink.svg";

import { ReactComponent as Bag } from "../../assets/Bag.svg";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { _t } from "../../helpers/helpers";
import { addTosWishlist } from "../../helpers/functions";
import "../PopupCart/PopupCart.scss";
import { isActiveContext, tokenContext } from "../Store";
import AddToCartPopup from "./PopupAddToBasket";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ApiService from "../../api/axios";
const ProductCard = (props) => {
  const [isActive, setIsActive] = useState(false);
  const [token, setToken] = useContext(tokenContext);
  const handleClick = () => {
    setIsActive(!isActive);
  };
  const removeButton = (e, id) => {
    ApiService.post("account/addWishList", { product_id: id }).then((resp) => {
      localStorage.setItem("wishlist", resp.data.wishlist);
      window.location.reload();
    });
  };

  return (
    <div>
      {isActive ? (
        <AddToCartPopup
          key={`product_item_${props.product.id}`}
          product={props.product}
        />
      ) : null}
      <div className="productCard">
        <div className="productCardHeader">
          <Link to={`/products/${props.product.alias}`}>
            <LazyLoadImage
              src={props.product.title_image.thumbnail}
              alt="product img"
              className="aboutImg"
              effect="blur"
            />
          </Link>
          {props.product.sticker ? (
            <div
              className="productCardBadge newProduct"
              style={{ backgroundColor: props.product.sticker.color }}
            >
              {props.product.sticker.title}
            </div>
          ) : null}
          {props.product.out_of_stock == 1 ? (
            <div className="productCardBadge stock">{_t("out of stock")}</div>
          ) : null}
          {token ? (
            <div>
              <label className="heart">
                <input
                  onClick={(e) => addTosWishlist(e, props.product.id)}
                  className="like-button"
                  checked={
                    localStorage.wishlist &&
                    localStorage.wishlist.includes(props.product.id)
                      ? "checked"
                      : null
                  }
                  type="checkbox"
                />
                <Heart />
              </label>
              <label className="likeTrash">
                <input
                  onClick={(e) => removeButton(e, props.product.id)}
                  className="trashButton"
                  checked={
                    localStorage.wishlist &&
                    localStorage.wishlist.includes(props.product.id)
                      ? "checked"
                      : null
                  }
                  type="checkbox"
                />
                Sil
              </label>
            </div>
          ) : null}

          {props.product.sale_percent &&
          Number(props.product.sale_percent) > 0 ? (
            <div className="interest">{`${props.product.sale_percent} %`}</div>
          ) : null}

          {props.product.out_of_stock == 0 ? (
            <button className="productCardAddToCart" onClick={handleClick}>
              <Bag />
              {_t("buttons.add_to_basket")}
            </button>
          ) : null}

          {/* {props.product.brand.is_special == 0 ? (
            <div className="hoverEffectSpan">
              Şəkil tanıtım xarakteri daşıyır. Ətir İydə qablaşdırılmasında
              çatdırılır.
            </div>
          ) : null} */}
        </div>
        <div className="productCardBody">
          <div className="mobileproductCardBOdy">
            <div className="productCardBodyHeader">
              {props.product.min_price === props.product.max_price ? (
                <div className="price blackPrice">
                  <span>
                    {props.product.min_price}
                    <svg
                      width="15"
                      height="12"
                      viewBox="0 0 15 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.9093 3.60719C4.557 4.06825 2.67879 6.73225 2.67879 10.0546C2.67879 10.23 2.6861 10.4048 2.69677 10.5813L2.7128 10.8464H2.44722H1.28253H1.05373L1.03351 10.6184C1.01115 10.3665 1 10.1109 1 9.8526C1 5.94261 3.58292 2.67642 6.95844 2.26336L6.97544 1.7981L6.98115 1.64185L7.12414 1.5786L7.97759 1.20106L8.31907 1.05L8.32865 1.42327L8.35007 2.25791C11.749 2.64532 14.3572 5.92401 14.355 9.85274M6.9093 3.60719L14.0724 10.5964H13.0877C13.0874 10.6014 13.0871 10.6064 13.0868 10.6114L12.8373 10.5964V10.3464H12.8498C12.8535 10.2495 12.8557 10.1523 12.8557 10.0546C12.8557 6.6395 10.9089 3.81635 8.37751 3.35253L8.38415 3.6084M6.9093 3.60719C6.99259 3.59086 7.07649 3.5773 7.16091 3.56657L6.94233 9.67179L6.83584 9.71642L6.93248 9.94699L7.18231 9.95593L7.18645 9.84054L8.28369 9.38068L8.133 3.5673C8.21727 3.5782 8.301 3.59192 8.38415 3.6084M6.9093 3.60719L6.68264 9.93804L6.66865 10.3286L7.02911 10.1776L8.38032 9.61124L8.53804 9.54514L8.53361 9.3742L8.38415 3.6084M14.355 9.85274L14.1058 9.8526H14.355C14.355 9.85265 14.355 9.85269 14.355 9.85274ZM14.355 9.85274C14.355 10.1109 14.3438 10.3665 14.3215 10.6184L14.3012 10.8464H14.0724H12.8373H12.5717L12.5877 10.5813C12.5984 10.4048 12.6057 10.23 12.6057 10.0546C12.6057 6.7359 10.7324 4.07382 8.38415 3.6084"
                        fill="#FF0852"
                        stroke="#FF0852"
                        strokeWidth="0.5"
                      />
                    </svg>
                  </span>
                </div>
              ) : (
                <>
                  {props.product.min_price_sale != props.product.min_price &&
                  props.product.max_sale_price != props.product.max_price ? (
                    <div className="price">
                      <span>
                        {props.product.min_price_sale}
                        <ManatPink />
                      </span>
                      {props.product.max_sale_price === 0 ? null : (
                        <>
                          <span>-</span>
                          <span>
                            {props.product.max_sale_price}
                            <ManatPink />
                          </span>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="price blackPrice">
                      <span>
                        {props.product.min_price}
                        <svg
                          width="15"
                          height="12"
                          viewBox="0 0 15 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.9093 3.60719C4.557 4.06825 2.67879 6.73225 2.67879 10.0546C2.67879 10.23 2.6861 10.4048 2.69677 10.5813L2.7128 10.8464H2.44722H1.28253H1.05373L1.03351 10.6184C1.01115 10.3665 1 10.1109 1 9.8526C1 5.94261 3.58292 2.67642 6.95844 2.26336L6.97544 1.7981L6.98115 1.64185L7.12414 1.5786L7.97759 1.20106L8.31907 1.05L8.32865 1.42327L8.35007 2.25791C11.749 2.64532 14.3572 5.92401 14.355 9.85274M6.9093 3.60719L14.0724 10.5964H13.0877C13.0874 10.6014 13.0871 10.6064 13.0868 10.6114L12.8373 10.5964V10.3464H12.8498C12.8535 10.2495 12.8557 10.1523 12.8557 10.0546C12.8557 6.6395 10.9089 3.81635 8.37751 3.35253L8.38415 3.6084M6.9093 3.60719C6.99259 3.59086 7.07649 3.5773 7.16091 3.56657L6.94233 9.67179L6.83584 9.71642L6.93248 9.94699L7.18231 9.95593L7.18645 9.84054L8.28369 9.38068L8.133 3.5673C8.21727 3.5782 8.301 3.59192 8.38415 3.6084M6.9093 3.60719L6.68264 9.93804L6.66865 10.3286L7.02911 10.1776L8.38032 9.61124L8.53804 9.54514L8.53361 9.3742L8.38415 3.6084M14.355 9.85274L14.1058 9.8526H14.355C14.355 9.85265 14.355 9.85269 14.355 9.85274ZM14.355 9.85274C14.355 10.1109 14.3438 10.3665 14.3215 10.6184L14.3012 10.8464H14.0724H12.8373H12.5717L12.5877 10.5813C12.5984 10.4048 12.6057 10.23 12.6057 10.0546C12.6057 6.7359 10.7324 4.07382 8.38415 3.6084"
                            fill="#FF0852"
                            stroke="#FF0852"
                            strokeWidth="0.5"
                          />
                        </svg>
                      </span>
                      {props.product.max_price === 0 ? null : (
                        <>
                          <span>-</span>
                          <span>
                            {props.product.max_price}
                            <svg
                              width="15"
                              height="12"
                              viewBox="0 0 15 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.9093 3.60719C4.557 4.06825 2.67879 6.73225 2.67879 10.0546C2.67879 10.23 2.6861 10.4048 2.69677 10.5813L2.7128 10.8464H2.44722H1.28253H1.05373L1.03351 10.6184C1.01115 10.3665 1 10.1109 1 9.8526C1 5.94261 3.58292 2.67642 6.95844 2.26336L6.97544 1.7981L6.98115 1.64185L7.12414 1.5786L7.97759 1.20106L8.31907 1.05L8.32865 1.42327L8.35007 2.25791C11.749 2.64532 14.3572 5.92401 14.355 9.85274M6.9093 3.60719L14.0724 10.5964H13.0877C13.0874 10.6014 13.0871 10.6064 13.0868 10.6114L12.8373 10.5964V10.3464H12.8498C12.8535 10.2495 12.8557 10.1523 12.8557 10.0546C12.8557 6.6395 10.9089 3.81635 8.37751 3.35253L8.38415 3.6084M6.9093 3.60719C6.99259 3.59086 7.07649 3.5773 7.16091 3.56657L6.94233 9.67179L6.83584 9.71642L6.93248 9.94699L7.18231 9.95593L7.18645 9.84054L8.28369 9.38068L8.133 3.5673C8.21727 3.5782 8.301 3.59192 8.38415 3.6084M6.9093 3.60719L6.68264 9.93804L6.66865 10.3286L7.02911 10.1776L8.38032 9.61124L8.53804 9.54514L8.53361 9.3742L8.38415 3.6084M14.355 9.85274L14.1058 9.8526H14.355C14.355 9.85265 14.355 9.85269 14.355 9.85274ZM14.355 9.85274C14.355 10.1109 14.3438 10.3665 14.3215 10.6184L14.3012 10.8464H14.0724H12.8373H12.5717L12.5877 10.5813C12.5984 10.4048 12.6057 10.23 12.6057 10.0546C12.6057 6.7359 10.7324 4.07382 8.38415 3.6084"
                                fill="#FF0852"
                                stroke="#FF0852"
                                strokeWidth="0.5"
                              />
                            </svg>
                          </span>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
              {props.product.min_price_sale != props.product.min_price &&
              props.product.max_sale_price != props.product.max_price ? (
                <div className="price priceDiscount">
                  <span className="discount">
                    {props.product.min_price}
                    <ManatBlack />
                  </span>
                  {props.product.max_price === 0 ? null : (
                    <>
                      <span>-</span>
                      <span className="discount">
                        {props.product.max_price}
                        <ManatBlack />
                      </span>
                    </>
                  )}
                </div>
              ) : null}
            </div>

            <div className="productCardRating desk-none">
              <Rating
                key={props.product.id}
                fillColor={"#FF0852"}
                size={10}
                ratingValue={props.product.rating} /* Available Props */
              />
            </div>
          </div>
          <Link t to={`/products/${props.product.alias}`} className="title">
            {props.product.title}
          </Link>
          <div className="productCardRating resp-none">
            <Rating
              fillColor={"#FF0852"}
              readonly={true}
              allowHalfIcon={true}
              iconsCount={5}
              size={20}
              ratingValue={props.product.rating * 20} /* Available Props */
            />
          </div>
          {props.product.out_of_stock == 1 ? null : (
            <button className="mobileAddToCart" onClick={handleClick}>
              <Bag />
              {_t("buttons.add_to_basket")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default trackWindowScroll(ProductCard);
