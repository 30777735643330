import React, { useEffect, useState } from "react";
import "./Selected.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ProductCard from "../../ProductCard";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import ApiService from "../../../api/axios";
import { _t } from "../../../helpers/helpers";

SwiperCore.use([Navigation]);

const Selected = () => {
  const [stickers, setStickers] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    ApiService.get("helpers/stickers").then((resp) => {
      // console.log(resp.data.data)
      setStickers(resp.data.data);
      console.log(resp.data);
    });
    ApiService.get("index/products_by_stickers").then((resp) => {
      setProducts(resp.data.data);
      console.log(resp.data);
    });
  }, []);

  return (
    <div className="selectedForYou productSlides">
      <div className="container">
        <Tabs>
          <div className="selectedForYouHeader">
            <h4 className="title">{_t("title.selected_for_you")}</h4>
            <TabList>
              {stickers.map((sticker) => (
                <Tab key={sticker.id}>{sticker.title}</Tab>
              ))}
            </TabList>
          </div>
          {Object.keys(products).map((key, product) => {
            return (
              <TabPanel key={key}>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={16}
                  slidesPerView={4}
                  loop={false}
                  navigation
                  pagination={{ clickable: true }}
                  breakpoints={{
                    1199: {
                      slidesPerView: 4,
                    },
                    767: {
                      slidesPerView: 3,
                    },
                    500: {
                      slidesPerView: 1.8,
                    },
                    320: {
                      slidesPerView: 1.2,
                    },
                  }}
                >
                  {products[key].map((item) => {
                    return (
                      <SwiperSlide key={item.id}>
                        <ProductCard product={item} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default Selected;
