import axios from "axios";
import Swal from "sweetalert2";
let headers;
let baseURL;
if (process.env.NODE_ENV === "development") {
  baseURL = "https://api.iyde.az/api/";
} else if (process.env.NODE_ENV === "production") {
  baseURL = "https://api.iyde.az/api/";
}

try {
  headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Language: localStorage.getItem("locale"),
  };
} catch (e) {
  console.log(e);
}

const ApiService = axios.create({
  baseURL,
  timeout: 0,
  headers: headers,
});

ApiService.interceptors.request.use(
  function (config) {
    if (localStorage.token) {
      // add token to request headers31
      config.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "token"
      )}`;
    }
    config.headers["Language"] = localStorage.getItem("locale");
    // document.body.classList.add("loading-indicator");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

ApiService.interceptors.response.use(
  (response) => {
    // document.body.classList.remove("loading-indicator");
    if (response.data.success) {
      return response;
    } else {
      // Swal.fire(response.data.title, response.data.message, "error");
    }
  },
  (error) => {
    // document.body.classList.remove("loading-indicator");

    // if (error.response.success === 401) {
    //     // Swal.fire(error.response.data.message, '', 'error');
    //     // localStorage.clear();
    //     // router.push('/login')
    // } else {
    console.log(error);
    // Swal.fire("Error", "Undefined error", "error").then((err) => {
    // window.location.reload();
    // });
    // }
  }
);

export default ApiService;
