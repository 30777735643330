import React, { useContext, useEffect, useState } from "react";
import "./HeaderBottom.scss";
import { Link, Redirect } from "react-router-dom";
import { ReactComponent as Arrow } from "../../../assets/arrow.svg";
import ApiService from "../../../api/axios";
import { _t } from "../../../helpers/helpers";
import { localeContext, tokenContext } from "../../Store";

const HeaderBottom = (props) => {
  const [headerLists, setHeaderLists] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [special_brand_menus, setSpecial_brand_menus] = useState([]);
  const [locale, setLocale] = useContext(localeContext);
  const [token, setToken] = useContext(tokenContext);
  const [menuBrands, setMenuBrands] = useState([]);
  const [brandsList, setBrandsList] = useState({});
  useEffect(() => {
    ApiService.get("helpers/menu/header_menu").then((resp) => {
      setHeaderLists(resp.data.data);
      setMenuBrands(resp.data.menu_brands);
      setCampaigns(resp.data.campaigns);
      setSpecial_brand_menus(resp.data.special_brand_menus);
    });
    ApiService.get("/brands").then((respp) => {
      setBrandsList(respp.data.data);
      console.log(brandsList);
    });
  }, [locale, token]);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleSubMenuClick = () => {
    setIsSubMenuOpen(true);
  };

  const handleSubMenuItemClick = () => {
    setIsSubMenuOpen(false);
  };
  return (
    <div className="headerBottom header-sectionn">
      <div className="headerBottomParent">
        <div className="container">
          <div className="row">
            <div className="xl-1 lg-1 md-1 sm-12"></div>
            <div className="xl-11 lg-12 md-12 sm-12">
              <div className="headerBottomChild">
                <div className="headerBottomMenuParent">
                  <Link to="/products" onMouseEnter={handleSubMenuClick}>
                    {_t("title.menu.perfumes")}
                    <Arrow />
                  </Link>
                  {isSubMenuOpen && (
                    <div className="headerBottomMenu">
                      <div className="container">
                        <div className="headerBottomMenuChild">
                          <Link to="/specials" className="title">
                            {_t("title.menu.special_collection")}
                          </Link>
                          <ul className="headerBottomMenuUl">
                            {special_brand_menus.map((special) => {
                              return (
                                <li>
                                  <Link
                                    to={`${special.alias}`}
                                    onClick={handleSubMenuItemClick}
                                  >
                                    {special.title}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        {menuBrands == null
                          ? null
                          : menuBrands.map((menuBrand) => {
                              return (
                                <div
                                  key={menuBrand.alias}
                                  className="headerBottomMenuChild"
                                >
                                  <Link
                                    to={`${menuBrand.alias}`}
                                    className="title"
                                    onMouseEnter={handleSubMenuClick}
                                  >
                                    {menuBrand.title}
                                  </Link>
                                  <ul className="headerBottomMenuUl">
                                    {menuBrand.sub_menu?.map((sub) => {
                                      return (
                                        <li key={sub.alias}>
                                          <Link
                                            to={`${sub.alias}`}
                                            onClick={handleSubMenuItemClick}
                                          >
                                            {sub.title}
                                          </Link>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              );
                            })}
                        <div className="headerBottomMenuChild">
                          {campaigns.map((campaign) => {
                            return (
                              <Link
                                to={`${campaign.alias}`}
                                className="title campaignsTitle"
                                onClick={handleSubMenuItemClick}
                              >
                                {campaign.title}
                              </Link>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* brands */}
                {/* <div className="headerBottomMenuParent">
                  <Link to="/brands">{_t("title.brands")}</Link>
                </div> */}
                <div className="headerBottomMenuParent">
                  <Link to="/brands" onMouseEnter={handleSubMenuClick}>
                    {_t("title.brands")}
                    <Arrow />
                  </Link>
                  {isSubMenuOpen && (
                    <div className="headerBottomMenu">
                      <div className="container">
                        <div className="headerBottomMenuChild brandListMenu">
                          <div className="row">
                            {Object.keys(brandsList).map((key) => {
                              return (
                                <div className="xl-2 lg-2 md-2 sm-12">
                                  {/* <span className="brandsAll">
                                    <Link
                                      to="/brands"
                                      onClick={handleSubMenuItemClick}
                                    >
                                      {" "}
                                      {_t("all.brands")}
                                    </Link>
                                  </span> */}
                                  <span>{key}</span>
                                  <ul>
                                    {brandsList[key].map((brandItem) => {
                                      return (
                                        <li>
                                          <Link
                                            to={`${brandItem.alias}`}
                                            onClick={handleSubMenuItemClick}
                                          >
                                            {brandItem.title}
                                          </Link>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {headerLists.map((headerList) => {
                  return (
                    <div className="headerBottomMenuParent oterMenuSection">
                      <Link
                        to={`/${headerList.path}`}
                        key={`menus_${headerList.path}`}
                        onMouseEnter={handleSubMenuClick}
                      >
                        {headerList.title}
                        {headerList.sub_menus?.length == 0 ? null : <Arrow />}
                      </Link>
                      {headerList.sub_menus?.length == 0 ? null : (
                        <div className="headerBottomMenu">
                          <div className="headerBottomMenuChild">
                            <ul className="headerBottomMenuUl">
                              {headerList.sub_menus.map((sub_menu) => {
                                return (
                                  <li key={`submenus_${sub_menu.path}`}>
                                    <Link
                                      to={`/${sub_menu.path}`}
                                      onClick={handleSubMenuItemClick}
                                    >
                                      {sub_menu.title}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBottom;
